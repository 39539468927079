import * as React from "react"

import Layout from "../../components/Layout/Layout"
import MasonryGrid from "../../components/MasonryGrid/MasonryGrid"

import Seo from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

import * as styles from "./AllArtworks.module.scss"
import { useMemo } from "react"

const LoadableGalleryMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const AllArtworks = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLot {
        edges {
          node {
            lotId
            auctionId
            asset {
              ... on ContentfulNftAsset {
                id
                yearOfWork
                slug
                artist {
                  name
                  slug
                }
                mainMedia {
                  gatsbyImageData
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
              ... on ContentfulPhysicalAsset {
                id
                yearOfWork
                slug
                artist {
                  name
                  slug
                }
                mainMedia {
                  gatsbyImageData
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
            }
            additionalMedia {
              gatsbyImageData
              title
              file {
                contentType
                url
              }
            }
          }
        }
      }
    }
  `)

  const organizationId = useMemo(() => {
    return parseInt(process.env.GATSBY_MONEGRAPH_ORGANIZATION_ID as string)
  }, [])

  const auctions = useMemo(() => {
    return getAuctionsIds(data.allContentfulLot.edges)
  }, [data])

  return (
    <>
      <Seo title="Auctions" />
      <div className={styles.allArtwork}>
        <h1>All Artworks</h1>
        <LoadableGalleryMonitor
          organizationId={organizationId}
          auctions={auctions}
          render={({ result }: any) => {
            const enrichedData = enricheLotWithAuction(
              data?.allContentfulLot?.edges || [],
              result?.auctions
            )
            return <MasonryGrid data={enrichedData} />
          }}
        />
      </div>
    </>
  )
}

export default AllArtworks
